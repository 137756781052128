import {startOfWeek, format, endOfWeek} from 'date-fns';
import { useTranslation } from 'react-i18next';
import {ReactComponent as NoteIcon} from "../assets/icons/note.svg";
import {ReactComponent as CrownIcon} from "../assets/icons/crown.svg";
import {ReactComponent as Logo} from "../assets/icons/leo-logo.svg";
import {ReactComponent as LeaderBoard} from "../assets/images/leaderboard.svg";
import {ReactComponent as MainPrize} from "../assets/images/main_prize_illustration.svg";
import {ReactComponent as WeeklyPrize} from "../assets/images/weekly_prize_illustration.svg";
import mainPageLogoFR from '../assets/images/main_visual_fr.png';
import mainPageLogoNL from '../assets/images/main_visual_nl.png';
import {ReactComponent as LoadingIcon} from "../assets/icons/loading.svg";
import {Button} from "../components/button";
import {Text} from "../components/text";
import {Card} from "../components/card";
import {TimePill} from "../components/time-pill";
import {cn} from "../utils";
import {NotesBg} from "../components/notes-bg";
import {useRecords} from "../hooks/useRecords";
import {useLinkFormatter} from "../hooks/useLinkFormatter";

const RECORD_STYLES = [
  'left-1/2 -translate-x-1/2 md:-top-24 -top-16',
  'md:left-7 md:-top-9 left-3 max-md:bottom-0',
  'md:right-6 md:top-3 right-3 max-md:bottom-0',
];

export const Main = () => {
  const { t, i18n } = useTranslation();
  const { data: voices } = useRecords();
  const formatLink = useLinkFormatter();

  return (
    <div className="max-w-screen-xl px-5 mx-auto">
      <section className="flex justify-center mb-10 mx-auto">
        <div className="flex flex-col items-center relative">
          <img className="object-center object-contain h-auto max-w-[800px] aspect-[832/630] w-full" src={i18n.language === 'nl' ? mainPageLogoNL : mainPageLogoFR}/>
          <Button color="yellow" className="mt-4 md:mt-0 md:absolute md:bottom-[100px] md:left-1/2 inline-flex md:-translate-x-1/2" to={formatLink('/participation')}>
            <NoteIcon />
            <span>{t('SING LEOOO')}</span>
          </Button>
        </div>
      </section>
      <section className="flex flex-col items-center py-11 md:py-24 relative">
        <div id="leaderboard" className="-mt-24 mb-24" />
        <div className="absolute inset-y-0 left-1/2 -translate-x-1/2 w-screen -z-20 bg-gradient-to-r from-[#7d69ac] to-[#7d69ac] via-[#a593cf]" />
        <div className="absolute inset-y-0 left-1/2 -translate-x-1/2 w-screen mix-blend-screen overflow-hidden">
          <NotesBg />
          <NotesBg className="top-[900px]" />
          <NotesBg className="top-[1800px]" />
        </div>
        {!!voices?.length && (
          <>
            <Text as="div" className="md:mb-8 mb-4" size="h2" shadow="dark">{t('THIS WEEK LEADERBOARD')}</Text>
            <Text as="div" size="h4" className="md:mb-6 mb-4" shadow="lite" uppercase>
              {format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'd MMMM')} - {format(endOfWeek(new Date(), { weekStartsOn: 1 }), 'd MMMM')}
            </Text>
            <div className="md:mb-28 mb-20"><CrownIcon /></div>
            <div className="relative md:mb-5 mb-8 max-md:pb-20">
              {voices.slice(0, 3).map(({ name, duration }, i) => (
                <div key={i} className={cn('flex flex-col justify-center items-center absolute', RECORD_STYLES[i])}>
                  <Text as="div" shadow="lite" size="h4" uppercase className="md:mb-5 mb-3">{name}</Text>
                  <TimePill color={!i ? 'yellow' : 'purple'} desktopColor={!i ? undefined : 'default'} sec={duration ?? 0}/>
                </div>
              ))}
              <LeaderBoard className="w-full max-w-[617px] aspect-[617/261] h-auto" />
            </div>
            <div className="w-full max-w-[600px] md:mb-24 mb-10">
              {voices.slice(3).map(({ name, duration }, i) => (
                <Card key={i} size="m" className="flex justify-between items-center md:px-6 md:py-4 p-3 mb-2.5">
                  <Text as="div" shadow="lite" size="h5" uppercase>{i + 4}. {name}</Text>
                  <TimePill sec={duration ?? 0} />
                </Card>
              ))}
            </div>
          </>
        )}
        {!voices && (
          <div className="py-20 flex items-center justify-center mb-10">
            <LoadingIcon className="animate-spin" />
          </div>
        )}
        <div className="w-full">
          <div id="prizes" className="-mt-24 mb-24" />
          <Text as="div" center shadow="dark" size="h2" uppercase className="md:mb-6 mb-4">{t('Amazing prizes')}</Text>
          <Text as="div" center shadow="dark" size="h1" uppercase className="md:mb-9 mb-8" color="pink">{t('you can win!')}</Text>
          <div className="flex flex-col mb-10 items-center gap-8 min-[900px]:gap-24 min-[900px]:flex-row min-[900px]:justify-between min-[900px]:mb-6">
            <MainPrize className="flex-1 max-w-[570px] max-[900px]:w-full" />
            <div className="text-center min-[900px]:flex-1 min-[900px]:text-left">
              <Text as="div" shadow="dark" size="h2" uppercase className="mb-8">{t('Main prize')}</Text>
              <Text as="p" size="p" weight="normal" font="myriad" className="mb-2">
                {t('main_prize_desc1')}
              </Text>
              <Text as="p" size="p" weight="normal" font="myriad">
                {t('main_prize_desc2')}
              </Text>
            </div>
          </div>
          <div className="flex flex-col items-center gap-8 min-[900px]:gap-24 min-[900px]:flex-row min-[900px]:justify-between">
            <WeeklyPrize className="flex-1 max-w-[626px] min-[900px]:order-last max-[900px]:w-full" />
            <div className="text-center min-[900px]:flex-1 min-[900px]:text-left">
              <Text as="div" shadow="dark" size="h2" uppercase className="mb-8">{t('Weekly prize')}</Text>
              <Text as="p" size="p" weight="normal" font="myriad" className="mb-2">
                {t('weekly_prize_desc1')}
              </Text>
              <Text as="p" size="p" weight="normal" font="myriad">
                {t('weekly_prize_desc2')}
              </Text>
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-col items-center md:py-24 py-12 relative">
        <NotesBg/>
        <div id="info" className="-mt-24 mb-24" />
        <Text as="div" className="md:mb-8 mb-6" size="h2" shadow="dark" uppercase center>{t('About')}</Text>
        {false && (
          <Text as="div" className="md:mb-12 mb-8" size="h1" shadow="dark" color="pink" uppercase center>
            {t('Campaign info')}
          </Text>
        )}
        <div className="max-w-[660px] md:mb-14 mb-10">
          {([1, 2, 3] as const).map((i) => (
            <Text key={i} as="p" size="p" weight="normal" font="myriad" className="mb-2 last:mb-0" center html={t(`about_desc${i}`)} />
          ))}
        </div>
        <Logo/>
      </section>
    </div>
  );
}
