import {Text} from "./text";
import {useTranslation} from "react-i18next";
import {Button} from "./button";
import {FC} from "react";

export const RegulationModal: FC<{onClose: () => void}> = ({onClose}) => {
  const { t } = useTranslation();

  return (
    <div className="fixed z-20 flex justify-center items-center p-5 inset-0 backdrop-blur-lg bg-[#7d69ac99]">
      <div className="max-w-[440px] flex-1 p-10 bg-white rounded-[20px] space-y-8">
        <Text as="div" size="h3" color="blue" className="text-center">{t('REGULATIONS')}</Text>
        <Text as="div" size="p" color="blue" font="myriad" weight="normal">{t('reguldesc')}</Text>
        <Button onClick={onClose} className="w-full">{t('regulbutton')}</Button>
      </div>
    </div>
  );
};
